import React from 'react';
import 'animate.css';
import './Banner.scss';
import { Carousel, Col, Container, Row } from 'react-bootstrap';
import { useInView } from 'react-intersection-observer';
import bannershape from './../../../Assets/Images/Home/bannershape.svg';
import partner1 from './../../../Assets/Images/Home/patner1.png';
import partner2 from './../../../Assets/Images/Home/patner2.png';
import partner3 from './../../../Assets/Images/Home/patner3.png';

function Banner() {
    const { ref: bannerRef1, inView: bannerInView1 } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const { ref: bannerRef2, inView: bannerInView2 } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const { ref: bannerRef3, inView: bannerInView3 } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    return (
        <section>
            <div className="mainSlider" id='home'>
                <div className="trapezoid-image"></div>
                <Carousel slide={false}>
                    <Carousel.Item>
                        <Container>
                            <Row>
                                <Col lg={10} xl={8}>
                                    <div ref={bannerRef1} className={`banner ${bannerInView1 ? 'animate__animated animate__slideInDown' : ''}`}>
                                        <div className="companyname">Gadhiya Technologies</div>
                                        <h1>Software <span>Development</span>  </h1>
                                        <p>We’re a leading software development company offering professional offshore software solutions for Start-ups, SMBs, and Large Enterprises for the past 3+ years.</p>
                                        <div className="bnnerinfo animate__animated animate__slideInUp">
                                            <button className="btn-Lets">Let’s Talk<i className="iconfont icon-arrow-left"></i></button>
                                            <div className="trusted-partners">
                                                <div className="partners">
                                                    <div className="partner-img">
                                                        <img src={partner1} alt="Partner 1" />
                                                    </div>
                                                    <div className="partner-img">
                                                        <img src={partner2} alt="Partner 2" />
                                                    </div>
                                                    <div className="partner-img">
                                                        <img src={partner3} alt="Partner 3" />
                                                    </div>
                                                    <div className="partner-img">
                                                        <div className="count-inner">20+</div>
                                                    </div>
                                                </div>
                                                <div className="info">
                                                    <div className="text">Trusted Partners</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Container>
                            <Row>
                                <Col lg={10} xl={8}>
                                    <div ref={bannerRef2} className={`banner ${bannerInView2 ? 'animate__animated animate__slideInDown' : ''}`}>
                                        <div className="companyname">Gadhiya Technologies</div>
                                        <h1>Mobile App <span>Development</span> Service</h1>
                                        <p>As a custom mobile app development company, we are committed to helping startups and established businesses in streamlining their development process.</p>
                                        <div className="bnnerinfo animate__animated animate__slideInUp">
                                            <button className="btn-Lets">Let’s Talk<i className="iconfont icon-arrow-left"></i></button>
                                            <div className="trusted-partners">
                                                <div className="partners">
                                                    <div className="partner-img">
                                                        <img src={partner1} alt="Partner 1" />
                                                    </div>
                                                    <div className="partner-img">
                                                        <img src={partner2} alt="Partner 2" />
                                                    </div>
                                                    <div className="partner-img">
                                                        <img src={partner3} alt="Partner 3" />
                                                    </div>
                                                    <div className="partner-img">
                                                        <div className="count-inner">20+</div>
                                                    </div>
                                                </div>
                                                <div className="info">
                                                    <div className="text">Trusted Partners</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Container>
                            <Row>
                                <Col lg={10} xl={8}>
                                    <div ref={bannerRef3} className={`banner ${bannerInView3 ? 'animate__animated animate__slideInDown' : ''}`}>
                                        <div className="companyname">Gadhiya Technologies</div>
                                        <h1>Web <span>Design</span> and Development</h1>
                                        <p>We have been developing websites for over 3 years and together with our Clients we achieve successes with custom Web development services to bring your ideas to life.</p>
                                        <div className="bnnerinfo animate__animated animate__slideInUp">
                                            <button className="btn-Lets">Let’s Talk<i className="iconfont icon-arrow-left"></i></button>
                                            <div className="trusted-partners">
                                                <div className="partners">
                                                    <div className="partner-img">
                                                        <img src={partner1} alt="Partner 1" />
                                                    </div>
                                                    <div className="partner-img">
                                                        <img src={partner2} alt="Partner 2" />
                                                    </div>
                                                    <div className="partner-img">
                                                        <img src={partner3} alt="Partner 3" />
                                                    </div>
                                                    <div className="partner-img">
                                                        <div className="count-inner">20+</div>
                                                    </div>
                                                </div>
                                                <div className="info">
                                                    <div className="text">Trusted Partners</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Carousel.Item>
                </Carousel>

                <div className="bannershape">
                    <img src={bannershape} alt="banner shape" />
                </div>
                <div className="company-logo">
                    <div className="marquee">
                        <div className="marquee-content">
                            <h2 className="logo">Gadhiya Technologies <span></span></h2>
                            <h2 className="logo">Gadhiya Technologies <span></span></h2>
                            <h2 className="logo">Gadhiya Technologies <span></span></h2>
                            <h2 className="logo">Gadhiya Technologies <span></span></h2>
                            <h2 className="logo">Gadhiya Technologies <span></span></h2>
                            <h2 className="logo">Gadhiya Technologies <span></span></h2>
                            <h2 className="logo">Gadhiya Technologies <span></span></h2>
                            <h2 className="logo">Gadhiya Technologies <span></span></h2>
                            <h2 className="logo">Gadhiya Technologies <span></span></h2>
                            <h2 className="logo">Gadhiya Technologies <span></span></h2>
                            <h2 className="logo">Gadhiya Technologies <span></span></h2>
                            <h2 className="logo">Gadhiya Technologies <span></span></h2>
                            <h2 className="logo">Gadhiya Technologies <span></span></h2>
                            <h2 className="logo">Gadhiya Technologies <span></span></h2>
                            <h2 className="logo">Gadhiya Technologies <span></span></h2>
                            <h2 className="logo">Gadhiya Technologies <span></span></h2>
                            <h2 className="logo">Gadhiya Technologies <span></span></h2>
                            <h2 className="logo">Gadhiya Technologies <span></span></h2>
                            <h2 className="logo">Gadhiya Technologies <span></span></h2>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Banner;
