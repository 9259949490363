import React from 'react'
import { Container } from 'react-bootstrap'
import './RefundPolicy.scss'

function RefundPolicy() {
    return (
        <div id='RefundPolicy'>
            <Container className='mt-5 mb-5'>
                <h1 className='Refund-title'>Refund & Cancellation Policy</h1>
                <p>Effective Date: 1st Jun 2022</p>

                <h4>1. General Policy</h4>
                <p>1.1 Gadhiya Technologies strives to provide the highest quality of services to our customers. However, we understand that there may be occasions where you may need to request a refund or cancel a service. This Refund & Cancellation Policy outlines the terms under which refunds and cancellations will be considered.</p>

                <h4>2. Non-Refundable Services</h4>
                <p>2.1 Web Development, Mobile Application Development, and Software Development</p>
                <p>Payments for web development, mobile application development, and software development services are non-refundable once the payment is completed. This is due to the nature of the work and the resources invested in each project.</p>

                <p>2.2 Web Hosting Services</p>
                <p>Payments for web hosting services are non-refundable. Once the hosting service is activated, no refunds will be issued.</p>

                <h4>3. Server Purchases</h4>
                <p>3.1 No Refunds for Server Purchases</p>
                <p>Customers purchasing servers are not eligible for refunds under any circumstances. Once a server is purchased and provisioned, the sale is final.</p>

                <p>3.2 KYC Requirement</p>
                <p>All customers purchasing servers must complete KYC (Know Your Customer) verification. Services will not be activated without successful completion of KYC.</p>

                <h4>4. Cancellations</h4>
                <p>4.1 Service Cancellation</p>
                <p>Customers may request the cancellation of services at any time by contacting our support team. However, please note that cancelling a service does not entitle you to a refund, unless specified otherwise in the service agreement.</p>

                <p>4.2 Recurring Services</p>
                <p>To avoid being charged for the next billing cycle, cancellation requests for recurring services must be submitted at least 7 days before the next billing date.</p>

                <h4>5. Refund Process</h4>
                <p>5.1 Eligibility</p>
                <p>Refund requests will only be considered for services that are eligible for refunds as specified in this policy.</p>

                <p>5.2 Submission</p>
                <p>To request a refund, customers must contact our support team with the details of the service and the reason for the refund request.</p>

                <p>5.3 Review and Approval</p>
                <p>Refund requests will be reviewed and processed within 15 business days. If the refund is approved, the amount will be credited back to the original payment method.</p>

                <h4>6. Chargebacks</h4>
                <p>6.1 Disputed Charges</p>
                <p>If you believe there has been an error in billing, please contact our support team immediately to resolve the issue.</p>

                <p>6.2 Unauthorized Chargebacks</p>
                <p>Initiating a chargeback without contacting Gadhiya Technologies to resolve the issue first is a violation of this policy. Accounts involved in chargebacks may be suspended or terminated, and Gadhiya Technologies reserves the right to pursue legal action.</p>

                <h4>7. Changes to Services and Pricing</h4>
                <p>7.1 Price Changes</p>
                <p>Gadhiya Technologies reserves the right to change the prices of its services at any time. Customers will be notified of any price changes in advance, and the new prices will apply from the next billing cycle.</p>

                <p>7.2 Service Modifications</p>
                <p>We reserve the right to modify, suspend, or discontinue any part of our services at any time without prior notice. In such cases, refunds will be considered on a case-by-case basis.</p>

                <h4>8. Contact Us</h4>
                <p>If you have any questions or need further assistance regarding our Refund & Cancellation Policy, please contact us at:</p>

                <p>
                    Gadhiya Technologies<br />
                    Email:  info@gadhiyatechnologies.com <br />
                    Address:  219, Parle square, near by Akshar wadi, Dabholi Char Rasta, Pramukh Darshan Society, Dabholi, Surat, Gujarat 395004
                </p>
            </Container>
        </div>
    )
}

export default RefundPolicy
