import React from 'react'
import './TermOfUse.scss'
import { Container } from 'react-bootstrap'

function TermOfUse() {
    return (
        <div id='TermOfUse'>
            <Container className='mt-5 mb-5'>
                <h1 className='term-title'>Terms and Conditions</h1>
                <p>Effective Date: 1st Jun 2022</p>

                <h4>1. Introduction</h4>
                <p>
                    1.1 Welcome to Gadhiya Technologies. These Terms and Conditions ("Terms", "Agreement") govern your use of our website, products, and services ("Services").
                </p>
                <p>
                    1.2 By accessing or using our Services, you agree to be bound by these Terms. If you do not agree with these Terms, please do not use our Services.
                </p>

                <h4>2. Services</h4>
                <p>
                    2.1 Gadhiya Technologies provides web development, mobile application development, software development, and web hosting services.
                </p>
                <p>
                    2.2 We reserve the right to modify, suspend, or discontinue any part of our Services at any time without prior notice.
                </p>

                <h4>3. Account Registration</h4>
                <p>
                    3.1 To use certain features of our Services, you must register for an account. You agree to provide accurate and complete information during registration.
                </p>
                <p>
                    3.2 You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.
                </p>

                <h4>4. Payment Terms</h4>
                <p>
                    4.1 All payments for Services are non-refundable, except for server purchases, which are not eligible for refunds under any circumstances.
                </p>
                <p>
                    4.2 Payments must be made in accordance with the payment terms specified in your service agreement.
                </p>
                <p>
                    4.3 Late payments may incur additional fees and may result in the suspension or termination of Services.
                </p>

                <h4>5. User Obligations</h4>
                <p>
                    5.1 You agree to use our Services only for lawful purposes and in accordance with these Terms.
                </p>
                <p>
                    5.2 You agree not to:
                </p>
                <ul>
                    <li>Use our Services in any manner that could damage, disable, overburden, or impair our servers or networks.</li>
                    <li>Engage in any fraudulent, abusive, or illegal activity.</li>
                    <li>Upload, post, or transmit any content that is unlawful, harmful, or objectionable.</li>
                </ul>

                <h4>6. Backup Policy</h4>
                <p>
                    6.1 Gadhiya Technologies is not responsible for maintaining backups of customer data for websites, software, mobile apps, and servers.
                </p>
                <p>
                    6.2 Customers are advised to maintain their own backups regularly.
                </p>

                <h4>7. KYC and Verification</h4>
                <p>
                    7.1 Customers purchasing servers must complete KYC (Know Your Customer) and mobile verification.
                </p>
                <p>
                    7.2 Services will not be provided without successful verification.
                </p>

                <h4>8. Prohibited Activities</h4>
                <p>
                    8.1 FREENOM domains are not allowed.
                </p>
                <p>
                    8.2 Spamming, distribution of viruses, and other destructive activities are strictly prohibited.
                </p>
                <p>
                    8.3 Illegal use of our Services, system and network abuse, pornography, piracy, and autobot websites are not allowed.
                </p>

                <h4>9. Changes to Software/WebPanel/API</h4>
                <p>
                    9.1 Gadhiya Technologies reserves the right to update or change its software, web panel, or APIs at any time.
                </p>
                <p>
                    9.2 Customers will be notified of significant changes in advance whenever possible.
                </p>

                <h4>10. Termination</h4>
                <p>
                    10.1 Gadhiya Technologies reserves the right to terminate or suspend your account at any time for any reason, including but not limited to a breach of these Terms.
                </p>
                <p>
                    10.2 Upon termination, your right to use the Services will immediately cease.
                </p>

                <h4>11. Limitation of Liability</h4>
                <p>
                    11.1 Gadhiya Technologies shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, use, or goodwill.
                </p>
                <p>
                    11.2 Our liability for any claims arising out of or related to these Terms shall not exceed the amount you paid us for the Services in the six months preceding the claim.
                </p>

                <h4>12. Indemnification</h4>
                <p>
                    12.1 You agree to indemnify and hold Gadhiya Technologies harmless from any claims, damages, liabilities, and expenses arising out of your use of our Services or violation of these Terms.
                </p>

                <h4>13. Governing Law and Jurisdiction</h4>
                <p>
                    13.1 These Terms are governed by and construed in accordance with the laws of [Specify Jurisdiction].
                </p>
                <p>
                    13.2 Any disputes arising from these Terms will be resolved in the courts of [Specify Jurisdiction].
                </p>

                <h4>14. Miscellaneous</h4>
                <p>
                    14.1 These Terms constitute the entire agreement between you and Gadhiya Technologies regarding the use of our Services.
                </p>
                <p>
                    14.2 If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions will continue to be valid and enforceable.
                </p>
                <p>
                    14.3 Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights.
                </p>

                <h4>15. Contact Us</h4>
                <p>
                    15.1 If you have any questions about these Terms, please contact us at:
                </p>
                <p>
                    Gadhiya Technologies<br />
                    Email:  info@gadhiyatechnologies.com <br />
                    Address:  219, Parle square, near by Akshar wadi, Dabholi Char Rasta, Pramukh Darshan Society, Dabholi, Surat, Gujarat 395004
                </p>
            </Container>
        </div>
    )
}

export default TermOfUse
