import React from 'react';
import './Footer.scss';
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import Logo from '../../Assets/Images/Footer/logo.svg'
import { useNavigate } from 'react-router-dom';
function Footer() {
    const navigator = useNavigate()
    return (
        <div id='footer'>
            <Container>
                {/* <Row className='gy-3 gy-md-0'>
                    <Col md={6} className='my-auto'>
                        <h5>Subscribe To Our Newsletter</h5>
                    </Col>
                    <Col md={6}>
                        <InputGroup>
                            <Form.Control placeholder="Enter Your Email Address" aria-label="Enter Your Email Address" />
                            <Button>Subscribe</Button>
                        </InputGroup>
                    </Col>
                </Row> */}
                {/* <hr /> */}
                <Row className='gy-3 gy-lg-0'>
                    <Col sm={6} lg={3}>
                        <div className='leftside'>
                            <div className='logo'>
                                <img src={Logo} className='img-fluid' alt='logo' />
                            </div>

                            <p>Empowering businesses through cutting-edge technology solutions. Committed to excellence, transparency, and personalized service. Partner with us to drive innovation, achieve your goals, and ensure long-term success.</p>

                            <div className="socialicon">
                                {/* <a href="https://www.facebook.com/">
                                    <i className="iconfont icon-facebook"></i>
                                </a> */}
                                <a href="https://www.linkedin.com/company/gadhiya-technologies" target='_blank'>
                                    <i className="iconfont icon-linkedin"></i>
                                </a>
                                <a href="https://www.instagram.com/gadhiyatechnologies/" target='_blank'>
                                    <i className="iconfont icon-instagram"></i>
                                </a>
                                {/* <a href="https://x.com/?lang=en">
                                    <i className="iconfont icon-twitter"></i>
                                </a> */}
                            </div>
                        </div>
                    </Col>
                    <Col sm={6} lg={3} >
                        <h4>Service</h4>
                        <ul>
                            <li><a href="#service">Web Development</a></li>
                            <li><a href="#service">App Development</a></li>
                            <li><a href="#service">Desktop Software</a></li>
                            <li><a href="#service">Server Providers</a></li>
                        </ul>
                    </Col>
                    <Col sm={6} lg={3}>
                        <h4>Company</h4>
                        <ul>
                            <li>
                                <a href="#home">Home</a>
                            </li>
                            <li>
                                <a href="#aboutus">About Us</a>
                            </li>
                            <li>
                                <a href="#ourservice">Our Services</a>
                            </li>

                        </ul>
                    </Col>
                    <Col sm={6} lg={3}>
                        <h4>Get In Touch</h4>
                        <div className='conetcinfo'>
                            <a href="tel:+919054053820">
                                <i className="iconfont icon-call"></i>
                                +91 90540 53820
                            </a>
                            <a href="mailto:info@gadhiyatechnologies.com">
                                <i className="iconfont icon-mail"></i>
                                info@gadhiyatechnologies.com
                            </a>
                            <a href="https://maps.app.goo.gl/UuPepmMdfoT3LeC6A" target='_blank'>
                                <i className="iconfont icon-location"></i>
                                <span>
                                    219, Parle square, near by Akshar wadi, Dabholi Char Rasta, Pramukh Darshan Society, Dabholi, Surat, Gujarat 395004
                                </span>
                            </a>
                        </div>
                    </Col>
                </Row>
                <hr />
                <Row className='pb-3 gy-3 gy-md-0'>
                    <Col md={6}>
                        <span>Copyright 2021 Gadhiya, All Rights Reserved.</span>
                    </Col>
                    <Col md={6}>
                        <div className='otherpage'>
                            <a onClick={() => { navigator('/termofuse'); window.scrollTo({ top: 0, behavior: 'smooth' }); }}>Terms Of Use</a>
                            <a onClick={() => { navigator('/privacypolicy'); window.scrollTo({ top: 0, behavior: 'smooth' }) }}>Privacy Policy</a>
                            <a onClick={() => { navigator('/paymenttermsandconditions'); window.scrollTo({ top: 0, behavior: 'smooth' }); }}>Payment Terms and Conditions</a>
                            <a onClick={() => { navigator('/refundpolicy'); window.scrollTo({ top: 0, behavior: 'smooth' }); }}>Refund & Cancellation Policy</a>
                            <a onClick={() => { navigator('/iOS/androidpolicy'); window.scrollTo({ top: 0, behavior: 'smooth' }); }}>iOS/Android Privacy Policy</a>
                            <a onClick={() => { navigator('/price'); window.scrollTo({ top: 0, behavior: 'smooth' }); }}>Price</a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div >
    )
}

export default Footer;