import React from 'react'
import './TermsConditions.scss'
import { Container } from 'react-bootstrap'
function TermsConditions() {
    return (
        <div id='TermsConditions'>
            <Container className='mt-5 mb-5'>
                <h1 className='payment-title'>Payment Terms and Conditions</h1>
                <p>Effective Date: 1st Jun 2022</p>

                <h4>1. Payment Methods</h4>
                <p>
                    1.1 Gadhiya Technologies accepts payments through the following methods:
                </p>
                <ul>
                    <li>Credit/Debit Cards</li>
                    <li>Bank Transfers</li>
                    <li>Online Payment Gateways</li>
                    <li>Other methods as may be provided from time to time</li>
                </ul>
                <p>
                    1.2 Customers must ensure that their payment information is accurate and up to date.
                </p>

                <h4>2. Payment Obligations</h4>
                <p>
                    2.1 All services provided by Gadhiya Technologies must be paid for in full before they are rendered.
                </p>
                <p>
                    2.2 Payment for recurring services (such as web hosting, server rentals, etc.) must be made on or before the due date specified in the invoice.
                </p>

                <h4>3. Non-Refundable Payments</h4>
                <p>
                    3.1 Payments made for web development, mobile application development, and software development services are non-refundable once the payment is completed.
                </p>
                <p>
                    3.2 Customers purchasing servers are not eligible for refunds under any circumstances.
                </p>

                <h4>4. Late Payments</h4>
                <p>
                    4.1 Payments not received by the due date will incur a late fee as specified in the invoice or service agreement.
                </p>
                <p>
                    4.2 Gadhiya Technologies reserves the right to suspend or terminate services for accounts that are overdue.
                </p>

                <h4>5. Disputed Charges</h4>
                <p>
                    5.1 Customers must notify Gadhiya Technologies of any disputed charges within 3 days of the charge date.
                </p>
                <p>
                    5.2 Gadhiya Technologies will investigate and resolve any disputes in a timely manner.
                </p>

                <h4>6. Chargebacks</h4>
                <p>
                    6.1 Initiating a chargeback without first attempting to resolve the issue with Gadhiya Technologies is considered a violation of these terms and conditions.
                </p>
                <p>
                    6.2 Accounts involved in chargebacks may be suspended or terminated, and Gadhiya Technologies reserves the right to pursue legal action.
                </p>

                <h4>7. Price Changes</h4>
                <p>
                    7.1 Gadhiya Technologies reserves the right to change the prices of its services at any time.
                </p>
                <p>
                    7.2 Customers will be notified of any price changes in advance, and the new prices will apply from the next billing cycle.
                </p>

                <h4>8. Billing Cycle</h4>
                <p>
                    8.1 Services are billed on a monthly, quarterly, or annual basis, as specified in the service agreement.
                </p>
                <p>
                    8.2 Invoices are generated and sent to customers 5 days before the due date.
                </p>

                <h4>9. Taxes</h4>
                <p>
                    9.1 All prices are exclusive of taxes unless otherwise stated.
                </p>
                <p>
                    9.2 Customers are responsible for paying any applicable taxes in their jurisdiction.
                </p>

                <h4>10. Refund Policy</h4>
                <p>
                    10.1 As stated, payments for web development, mobile application development, and software development are non-refundable.
                </p>
                <p>
                    10.2 Server purchases are not eligible for refunds under any circumstances.
                </p>

                <h4>11. Account Credits</h4>
                <p>
                    11.1 Any credits issued to a customer’s account must be used within one year from the date of issuance.
                </p>
                <p>
                    11.2 Credits are non-transferable and cannot be exchanged for cash.
                </p>

                <h4>12. Payment Disclosures</h4>
                <p>
                    12.1 Customers agree to provide accurate billing information and authorize Gadhiya Technologies to charge the specified payment method for the agreed amount.
                </p>
                <p>
                    12.2 In case of failed payments, customers must provide an alternative payment method within 7 days to avoid service disruption.
                </p>

                <h4>13. Termination for Non-Payment</h4>
                <p>
                    13.1 Gadhiya Technologies reserves the right to terminate services immediately if payment is not received within 3 days of the due date.
                </p>
                <p>
                    13.2 Upon termination, all data and content associated with the customer’s account may be deleted.
                </p>

                <h4>14. Reinstatement of Services</h4>
                <p>
                    14.1 If services are suspended due to non-payment, customers may request reinstatement by paying all outstanding amounts, including any applicable late fees.
                </p>
                <p>
                    14.2 Reinstatement of services is subject to Gadhiya Technologies' discretion.
                </p>
            </Container>
        </div>
    )
}

export default TermsConditions
