import React from 'react';
import './Service.scss';
import { Col, Container, Row } from 'react-bootstrap';
import serviceshape from './../../../Assets/Images/Home/bannershape.svg';
const Service = () => {
    return (
        <section className="service-section" id='ourservice'>
            <Container>
                <Row className='gy-3 gy-md-0'>
                    <Col md={6}>
                        <div className="service-intro">
                            <p className="service-label animate__animated animate__flipInX">SERVICE</p>
                            <h2 className="service-title animate__animated animate__flipInX">We Kinds of Services Business</h2>
                            <p className="service-description animate__animated animate__slideInUp">
                                At Gadhiya Technologies, we specialize in comprehensive web development, software development, mobile development, and server provisioning services. Our expert team delivers cutting-edge web solutions tailored to meet the unique needs of businesses, from dynamic websites to robust e-commerce platforms.
                            </p>
                            <p className="service-description animate__animated animate__slideInUp">
                                In software development, we create customized applications that enhance operational efficiency and drive growth. Our mobile development services ensure your business stays connected with customers through intuitive, high-performance apps across all major platforms. Additionally, our reliable server provisioning guarantees secure and scalable hosting solutions, ensuring your digital infrastructure is always optimized. Trust Gadhiya Technologies for all your development and server needs, and experience unparalleled service and innovation.
                            </p>
                            <button className="service-button animate__animated animate__slideInUp" style={{ display: "none" }}>
                                More Service<i className="iconfont icon-arrow-left"></i>
                            </button>
                        </div>
                    </Col>

                    <Col md={6}>
                        <div className="service-cards">
                            <div className="service-card animate__animated animate__zoomIn">
                                <div className="icon-wrapper">
                                    <i className='iconfont icon-web-code'></i>
                                </div>
                                <h3 className="card-title">Web Development</h3>
                                <p className="card-description">Crafting dynamic, responsive websites tailored to your business needs.</p>
                            </div>
                            <div className="service-card animate__animated animate__zoomIn">
                                <div className="icon-wrapper">
                                    <i className='iconfont icon-app-setting'></i>
                                </div>
                                <h3 className="card-title">App Development</h3>
                                <p className="card-description">Building high-performance apps for seamless customer engagement.</p>
                            </div>
                            <div className="service-card animate__animated animate__zoomIn">
                                <div className="icon-wrapper">
                                    <i className='iconfont icon-development'></i>
                                </div>
                                <h3 className="card-title">Desktop Software</h3>
                                <p className="card-description">Creating custom applications that enhance efficiency and drive growth.</p>
                            </div>
                            <div className="service-card animate__animated animate__zoomIn">
                                <div className="icon-wrapper">
                                    <i className='iconfont icon-server-provider'></i>
                                </div>
                                <h3 className="card-title">Server Providers</h3>
                                <p className="card-description">Offering secure, scalable hosting solutions for optimized digital infrastructure.</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className='serviceshape'>
                <img src={serviceshape} alt="service shape" />
            </div>
        </section>
    );
};

export default Service;
