import React, { useState, useEffect } from 'react';
import './Header.scss';
import Container from 'react-bootstrap/Container';
import { Col, Nav, Navbar, Row } from 'react-bootstrap';
import Logo from '../../Assets/Images/Logo/logo.svg';
import { useNavigate } from 'react-router-dom';

function Header() {
    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            if (offset > 0) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const navigator = useNavigate()

    return (
        <>
            <div className={`topheader`}>
                <Container>
                    <Row className="align-items-center">
                        <Col>
                            <div className="contect">
                                <a href="mailto:info@gadhiyatechnologies.com">
                                    <i className="iconfont icon-mail"></i>
                                    info@gadhiyatechnologies.com
                                </a>
                                <a href="tel:+919054053820">
                                    <i className="iconfont icon-call"></i>
                                    +91 90540 53820
                                </a>
                            </div>
                        </Col>
                        <Col>
                            <div className="socialicon">
                                {/* <a href="https://www.facebook.com/">
                                    <i className="iconfont icon-facebook"></i>
                                </a> */}
                                <a href="https://www.linkedin.com/company/gadhiya-technologies" target='_blank'>
                                    <i className="iconfont icon-linkedin"></i>
                                </a>
                                <a href="https://www.instagram.com/gadhiyatechnologies/" target='_blank'>
                                    <i className="iconfont icon-instagram"></i>
                                </a>
                                {/* <a href="https://x.com/?lang=en">
                                    <i className="iconfont icon-twitter"></i>
                                </a> */}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Navbar expand="lg" className={`navbar ${isSticky ? 'is-sticky' : ''}`}>
                <Container>
                    <Navbar.Brand href="#">
                        <img src={Logo} alt="logo" className="img-fluid" onClick={() => navigator('/')} />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav className="mx-auto my-2 my-lg-0">
                            <Nav.Link href="#home" onClick={() => navigator('/')}>Home</Nav.Link>
                            <Nav.Link href="#aboutus" onClick={() => navigator('/')}>About Us</Nav.Link>
                            <Nav.Link href="#ourservice" onClick={() => navigator('/')}>Our Service</Nav.Link>
                            {/* <Nav.Link href="#blog">Blog</Nav.Link> */}
                        </Nav>
                        <a href="#contact" className="btn-contact" onClick={() => navigator('/')}>
                            Contact Us <i className="iconfont icon-arrow-left"></i>
                        </a>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
}

export default Header;