import React from 'react'
import "./Price.scss"
import img from "../../Assets/Images/Footer/price.png"

function Price() {
    return (
        <div>
            <p>Best & Cheap Windows VPS Hosting Server in India, Self Managed Windows VPS Hosting with 1Gbps Port Speed</p>
            <img src={img}></img>
        </div>
    )
}

export default Price
