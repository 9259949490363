import { useEffect } from 'react';
import './App.scss';
import './Assets/Icons/IconFont.css';
import Footer from './Components/Footer/Footer';
import Header from './Components/Header/Header';
import Home from './Components/Home/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import TermOfUse from './Components/TermOfUse/TermOfUse';
import PrivacyPolicy from './Components/PrivacyPolicy/PrivacyPolicy';
import TermsConditions from './Components/TermsConditions/TermsConditions';
import RefundPolicy from './Components/RefundPolicy/RefundPolicy';
import Iospolicy from './Components/iOSPolicy/iOSPolicy';
import Price from './Components/Price/Price';

function App() {

  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    document.addEventListener('contextmenu', handleContextMenu);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/termofuse" element={<TermOfUse />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/paymenttermsandconditions" element={<TermsConditions />} />
          <Route path="/refundpolicy" element={<RefundPolicy />} />
          <Route path="/iOS/androidpolicy" element={<Iospolicy />} />
          <Route path="/price" element={<Price />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
