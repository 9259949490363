import React from 'react';
import 'animate.css';
import './Aboutus.scss';
import { Col, Container, Row } from 'react-bootstrap';
import { useInView } from 'react-intersection-observer';
import Aboutus1 from './../../../Assets/Images/Home/aboutus1.png';
import Aboutus2 from './../../../Assets/Images/Home/aboutus2.png';

function Aboutus() {
    const { ref: imageRef, inView: imageInView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const { ref: textRef, inView: textInView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    return (
        <section id='aboutus'>
            <Container>
                <Row>
                    <Col lg={6} className='mb-5 mb-lg-0'>
                        <div ref={imageRef} className={`aboutusImage ${imageInView ? 'animate__animated animate__slideInUp' : ''}`}>
                            <div className='images'>
                                <img src={Aboutus1} className='img-fluid' />
                                <div className='contain'>
                                    <div className='text'>
                                        Smart team work
                                    </div>
                                    <div className='images'>
                                        <img src={Aboutus2} className='img-fluid' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div ref={textRef} className={`aboutus`}>
                            <div className={`aboutusname ${textInView ? 'animate__animated animate__flipInX' : ''}`}>ABOUT US</div>
                            <h3 className={`${textInView ? 'animate__animated animate__flipInX' : ''}`}>We have been developing software for over 3 years and together with our Clients</h3>
                            <p className={` ${textInView ? 'animate__animated animate__slideInUp' : ''}`}>
                                Gadhiya Technologies is a premier software development firm located in Gujarat, India, founded in 2018. Specializing in delivering top-tier web solutions, we cater to the needs of both small businesses and large corporations. Our expertise encompasses offshore web and software application development, ensuring superior quality and global standards. Our innovative and versatile team comprises IT strategic planners, designers, and marketing experts, all carefully selected to guide our clients in their online endeavours. Choose Gadhiya Technologies for cutting-edge, reliable, and effective web-based solutions.
                            </p>
                            <div className={`our ${textInView ? 'animate__animated animate__slideInUp' : ''}`}>
                                <div className='innerour'>
                                    <div className='icon'>
                                        <i className='iconfont icon-goal'></i>
                                    </div>
                                    <div className='content'>
                                        <h6>Our Mission</h6>
                                        <p>To deliver innovative web and software solutions that drive business success.</p>
                                    </div>
                                </div>
                                <div className='innerour'>
                                    <div className='icon'>
                                        <i className='iconfont icon-eye'></i>
                                    </div>
                                    <div className='content'>
                                        <h6>Our Vision</h6>
                                        <p>To be a global leader in software development, setting the standard for excellence and innovation.</p>
                                    </div>
                                </div>
                            </div>
                            <button className="btn-learnmore" style={{ display: "none" }}>Learn More<i className="iconfont icon-arrow-left"></i></button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Aboutus;
