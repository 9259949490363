import React from 'react'
import "./PrivacyPolicy.scss"
import { Container } from 'react-bootstrap'
function PrivacyPolicy() {
  return (
    <div id='PrivacyPolicy'>
      <Container className='mt-5 mb-5'>
        <h1 className='title-privacy' >Privacy Policy</h1>
        <p>Effective Date: 1st Jun 2022</p>
        <h4>1. Introduction</h4>
        <p>
          1.1 Gadhiya Technologies ("we", "us", "our") is committed to protecting the privacy of our customers ("you", "your"). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website or use our services.
        </p>
        <p>
          1.2 By accessing or using our services, you agree to the collection and use of information in accordance with this Privacy Policy.
        </p>

        <h4>2. Information We Collect</h4>

        <p>2.1 Personal Information</p>
        <ul>
          <li>Name</li>
          <li>Email address</li>
          <li>Phone number</li>
          <li>Billing information (including credit card numbers and billing addresses)</li>
          <li>Identification information (such as Aadhar number for KYC purposes)</li>
        </ul>

        <p>2.2 Non-Personal Information</p>
        <ul>
          <li>IP address</li>
          <li>Browser type</li>
          <li>Device information</li>
          <li>Usage data (including pages visited, time spent on pages, and other analytical data)</li>
        </ul>

        <h4>3. How We Use Your Information</h4>
        <p>
          3.1 We use the collected information for various purposes, including:
        </p>
        <ul>
          <li>Providing, operating, and maintaining our services</li>
          <li>Processing transactions and managing your orders</li>
          <li>Communicating with you, including responding to your inquiries and sending administrative and promotional emails</li>
          <li>Improving our website and services</li>
          <li>Conducting KYC and mobile verification</li>
          <li>Ensuring compliance with legal obligations and preventing fraud</li>
        </ul>

        <h4>4. How We Share Your Information</h4>
        <p>
          4.1 We do not sell or rent your personal information to third parties.
        </p>
        <p>
          4.2 We may share your information with:
        </p>
        <ul>
          <li>Service providers who assist in operating our business (such as payment processors and hosting providers)</li>
          <li>Legal authorities if required by law or to protect our rights</li>
          <li>Other parties with your consent or at your direction</li>
        </ul>

        <h4>5. Data Security</h4>
        <p>
          5.1 We implement appropriate security measures to protect your information from unauthorized access, alteration, disclosure, or destruction.
        </p>
        <p>
          5.2 While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.
        </p>

        <h4>6. Data Retention</h4>
        <p>
          6.1 We retain your personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.
        </p>

        <h4>7. Your Rights</h4>
        <p>
          7.1 You have the right to:
        </p>
        <ul>
          <li>Access and update your personal information</li>
          <li>Request the deletion of your personal information</li>
          <li>Object to or restrict the processing of your personal information</li>
          <li>Withdraw consent where we are relying on consent to process your personal information</li>
        </ul>
        <p>
          7.2 To exercise these rights, please contact us at info@gadhiyatechnologies.com
        </p>

        <h4>8. Cookies and Tracking Technologies</h4>
        <p>
          8.1 We use cookies and similar tracking technologies to track activity on our website and hold certain information.
        </p>
        <p>
          8.2 You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our services.
        </p>

        <h4>9. Third-Party Links</h4>
        <p>
          9.1 Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these third-party sites.
        </p>

        <h4>10. Children's Privacy</h4>
        <p>
          10.1 Our services are not intended for individuals under the age of 18. We do not knowingly collect personal information from children under 18. If we become aware that we have collected personal information from a child under 18, we will take steps to delete such information.
        </p>

        <h4>11. Changes to This Privacy Policy</h4>
        <p>
          11.1 We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website.
        </p>
        <p>
          11.2 You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
        </p>

        <h4>12. Contact Us</h4>
        <p>
          12.1 If you have any questions about this Privacy Policy, please contact us at:
        </p>
        <p>
          Gadhiya Technologies<br />
          Email:  info@gadhiyatechnologies.com <br />
          Address:  219, Parle square, near by Akshar wadi, Dabholi Char Rasta, Pramukh Darshan Society, Dabholi, Surat, Gujarat 395004
        </p>
      </Container>
    </div >
  )
}

export default PrivacyPolicy
