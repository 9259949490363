import React from 'react';
import './Home.scss';
import Banner from './Banner/Banner';
import Aboutus from './Aboutus/Aboutus';
import Service from './Service/Service';
import Count from './Counter/Counter';
import Steps from './Steps/Steps';
import Blog from './Blog/Blog';
import Contactus from './Contactus/Contactus';

function Home() {
    return (
        <>
            <Banner />
            <Aboutus  />
            <Service />
            {/* <Count /> */}
            <Steps />
            {/* <Blog /> */}
            <Contactus />
        </>
    )
}

export default Home;