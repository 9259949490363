import React from 'react'
import { Container } from 'react-bootstrap'
import './iOSPolicy.scss'

function iOSPolicy() {
    return (
        <div id='iOSPolicy'>
            <Container className='mt-5 mb-5'>
                <h1 className='ios-title'>iOS/Android Privacy Policy</h1>
                <p>Effective Date: 1st Jun 2022</p>

                <h4>1. Introduction</h4>
                <p>1.1 Gadhiya Technologies ("we", "us", "our") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our mobile applications ("Apps") on iOS and Android devices.</p>
                <p>1.2 By downloading, installing, and using our Apps, you agree to the terms of this Privacy Policy. If you do not agree with these terms, please do not use our Apps.</p>

                <h4>2. Information We Collect</h4>
                <p>2.1 Personal Information</p>
                <p>When you use our Apps, we may collect personal information, including but not limited to:</p>
                <ul>
                    <li>Name</li>
                    <li>Email address</li>
                    <li>Phone number</li>
                    <li>Device information</li>
                    <li>Location data (with your consent)</li>
                    <li>Payment information (if applicable)</li>
                </ul>

                <p>2.2 Non-Personal Information</p>
                <p>We may also collect non-personal information about your use of our Apps, such as:</p>
                <ul>
                    <li>App usage data</li>
                    <li>Interaction data (e.g., features used, preferences)</li>
                    <li>Crash reports and performance data</li>
                </ul>

                <h4>3. How We Use Your Information</h4>
                <p>3.1 We use the information we collect for various purposes, including:</p>
                <ul>
                    <li>Providing, operating, and maintaining our Apps</li>
                    <li>Personalizing and improving your user experience</li>
                    <li>Communicating with you, including sending notifications and updates</li>
                    <li>Processing transactions and managing your orders (if applicable)</li>
                    <li>Ensuring compliance with legal obligations and preventing fraud</li>
                </ul>

                <h4>4. How We Share Your Information</h4>
                <p>4.1 We do not sell or rent your personal information to third parties.</p>
                <p>4.2 We may share your information with:</p>
                <ul>
                    <li>Service providers who assist in operating our business (e.g., payment processors, analytics providers)</li>
                    <li>Legal authorities if required by law or to protect our rights</li>
                    <li>Other parties with your consent or at your direction</li>
                </ul>

                <h4>5. Data Security</h4>
                <p>5.1 We implement appropriate security measures to protect your information from unauthorized access, alteration, disclosure, or destruction.</p>
                <p>5.2 While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.</p>

                <h4>6. Data Retention</h4>
                <p>6.1 We retain your personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.</p>

                <h4>7. Your Rights</h4>
                <p>7.1 You have the right to:</p>
                <ul>
                    <li>Access and update your personal information</li>
                    <li>Request the deletion of your personal information</li>
                    <li>Object to or restrict the processing of your personal information</li>
                    <li>Withdraw consent where we are relying on consent to process your personal information</li>
                </ul>
                <p>7.2 To exercise these rights, please contact us at [your contact email].</p>

                <h4>8. Cookies and Tracking Technologies</h4>
                <p>8.1 Our Apps may use cookies and similar tracking technologies to track activity and hold certain information.</p>
                <p>8.2 You can instruct your device to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Apps.</p>

                <h4>9. Third-Party Services</h4>
                <p>9.1 Our Apps may contain links to third-party services or integrate with third-party platforms. We are not responsible for the privacy practices or content of these third-party services.</p>

                <h4>10. Children's Privacy</h4>
                <p>10.1 Our Apps are not intended for individuals under the age of 18. We do not knowingly collect personal information from children under 18. If we become aware that we have collected personal information from a child under 18, we will take steps to delete such information.</p>

                <h4>11. Changes to This Privacy Policy</h4>
                <p>11.1 We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last Updated" date.</p>
                <p>11.2 You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>

                <h4>12. Contact Us</h4>
                <p>12.1 If you have any questions about this Privacy Policy, please contact us at:</p>
                <p>
                    Gadhiya Technologies<br />
                    Email:  info@gadhiyatechnologies.com <br />
                    Address:  219, Parle square, near by Akshar wadi, Dabholi Char Rasta, Pramukh Darshan Society, Dabholi, Surat, Gujarat 395004
                </p>
            </Container>
        </div>
    )
}

export default iOSPolicy
