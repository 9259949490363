
import React, { useState } from 'react';
import './Contactus.scss';
import { Col, Container, Row } from 'react-bootstrap';
import axios from 'axios';

const Contactus = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [responseMessage, setResponseMessage] = useState('');
    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        const userData = {
            name: formData.name,
            email: formData.email,
            phone: formData.phone,
            subject: formData.subject,
            message: formData.message,
        };
        axios.post("/send", userData)
            .then((res) => {
                if (res.status === 200) {
                    setFormData({
                        name: '',
                        email: '',
                        phone: '',
                        subject: '',
                        message: ''
                    });
                    setResponseMessage(res.data.message);
                    setTimeout(() => {
                        setResponseMessage('');
                    }, 3000);
                }
            })
            .catch((error) => {
                // console.error("There was an error!", error);
            })
            .finally(() => {
                setIsSubmitting(false); // Re-enable the button
            });
    };

    return (
        <div className='contact' id='contact'>
            <Container>
                <Row>
                    <Col sm={12}>
                        <div className='contact-header'>
                            <p className="contact-title animate__animated animate__flipInX">Get in touch</p>
                            <h2 className="contact-subtitle animate__animated animate__flipInX">We Are Always Ready To Hear From You</h2>
                        </div>
                        <div className="contact-form">
                            <div className="contact-info animate__animated animate__zoomIn">
                                <div className='contact-details'>
                                    <h4>Contact Information</h4>
                                    <p>For further assistance or inquiries, don't hesitate to get in touch with our support team. We're here to help you every step of the way.</p>
                                </div>
                                <hr />
                                <div className='conetcinfo'>
                                    <a href="tel:+919054053820">
                                        <i className="iconfont icon-call"></i>
                                        +91 90540 53820
                                    </a>
                                    <a href="mailto:info@gadhiyatechnologies.com">
                                        <i className="iconfont icon-mail"></i>
                                        info@gadhiyatechnologies.com
                                    </a>
                                    <a href="https://maps.app.goo.gl/UuPepmMdfoT3LeC6A" target='_blank'>
                                        <i className="iconfont icon-location"></i>
                                        <span>
                                            219, Parle square, near by Akshar wadi, Dabholi Char Rasta, Pramukh Darshan Society, Dabholi, Surat, Gujarat 395004
                                        </span>
                                    </a>
                                </div>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className='contact-input'>
                                    <div className='input-form'>
                                        <label>Name</label>
                                        <input type="text" name="name" placeholder="Enter Name*" value={formData.name} onChange={handleChange} required />
                                    </div>
                                    <div className='input-form'>
                                        <label>Phone</label>
                                        <input type="tel" name="phone" placeholder="Enter Phone*" value={formData.phone} onChange={handleChange} required />
                                    </div>
                                    <div className='input-form'>
                                        <label>Email</label>
                                        <input type="email" name="email" placeholder="Enter Email*" value={formData.email} onChange={handleChange} required />
                                    </div>
                                    <div className='input-form'>
                                        <label>Subject</label>
                                        <input type="text" name="subject" placeholder="Enter Subject*" value={formData.subject} onChange={handleChange} required />
                                    </div>
                                    <div className='input-form'>
                                        <label>Message</label>
                                        <textarea name="message" cols={30} rows={5} placeholder="Type A Message*" value={formData.message} onChange={handleChange} required></textarea>
                                    </div>
                                </div>
                                <button type="submit" disabled={isSubmitting}>Send Message<i className="iconfont icon-arrow-left"></i></button>
                                {responseMessage && <div className="response-message" style={{ color: "green" }}>{responseMessage}</div>}
                            </form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Contactus;