// src/components/Steps.js
import React from 'react';
import './Steps.scss';
import { Col, Container, Row } from 'react-bootstrap';

function Steps() {

  const stepsData = [
    {
      icon: 'hand',
      title: 'Initial Consultation',
      description: 'Schedule a meeting with our team to discuss your project requirements and objectives.'
    },
    {
      icon: 'users',
      title: 'Development Phase',
      description: 'Our team begins the development process, incorporating your feedback to refine and perfect the solution.'
    },
    {
      icon: 'setting',
      title: 'Review and Adjust',
      description: 'Participate in review sessions to assess progress and make any necessary adjustments.'
    },
    {
      icon: 'hand-report',
      title: 'Final Delivery',
      description: 'Get the finished product, meticulously tested and ready for deployment.'
    }
  ];

  return (
    <div className='step'>
      <Container>
        <Row>
          <Col sm={12}>
            <p className="steps-heading animate__animated animate__flipInX">How Does It Work?</p>
            <h3 className="steps-subheading animate__animated animate__flipInX">Follow Some Simple Steps For Using Bestkit</h3>
          </Col>
        </Row>
        <Row>
          {stepsData.map((step, index) => (
            <Col md={6} lg={3} key={index} className='animate__animated animate__zoomIn'>
              <div className='stepinner '>
                <div className="step-icon"><i className={`iconfont icon-${step.icon}`}></i></div><div>
                  <h5 className="step-title">{step.title}</h5>
                  <p className="step-description">{step.description}</p>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default Steps;